a {
  &:focus {
    outline: none;
  }
}

// Component Code
.component-code {
  pre {
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: $body-bg;
    }
  }
}
