@import './bootstrap.scss';
@import './components.scss';
@import './custom.scss';
@import './bootstrap-extended.scss';
@import './colors.scss';
@import './iconfont.scss';
@import './custom-rtl.scss';
@import './iphone.scss';

.ql-clipboard {
  display: none;
}

.unique-1137-options {
  padding: 0;
}

.unique-1337-nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.unique-1337-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  list-style: none;
}

.unique-1337-nav-tabs.unique-1337-wizard {
  display: flex;
  justify-content: center;
  width: 100%;
  clear: both;
  border-bottom: none;
  margin-bottom: 0.5em;
  transform: translateX(-10px);
}

.unique-1337-nav-tabs.unique-1337-wizard li {
  position: relative;
  padding: 0;
  margin: 4px 4px 0 0;
  float: left;
  text-align: center;
}

.unique-1337-nav-tabs.unique-1337-wizard li a {
  padding-left: 30px;
}

.unique-1337-nav-tabs.unique-1337-wizard li.unique-1337-completed > * {
  color: #fff !important;
  background-color: #59c03d !important;
  border-color: #59c03d !important;
  border-bottom: none !important;
}

.unique-1337-nav-tabs.unique-1337-wizard li > * {
  position: relative;
  padding: 0.8em 0.8em 0.6em 1.5em;
  color: #333;
  background-color: #dedede;
  border-color: #dedede;
}

.unique-1337-nav-tabs.unique-1337-wizard li.unique-1337-active > * {
  color: white;
  background-color: var(--primary);
  border-color: var(--primary);
  border-bottom: none !important;
}

.unique-1337-nav > li > a {
  display: block;
  white-space: nowrap;
  text-decoration: none;
  pointer-events: none;
}

.unique-1337-nav-tabs.unique-1337-wizard li::after {
  z-index: 1;
  -webkit-transform: translateX(4px);
  -moz-transform: translateX(4px);
  -ms-transform: translateX(4px);
  -o-transform: translateX(4px);
  transform: translateX(4px);
  border-left-color: #fff !important;
  margin: 0;
}

.unique-1337-nav-tabs.unique-1337-wizard li::after,
.unique-1337-nav-tabs.unique-1337-wizard li > *::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 0;
  width: 0;
  border: 20px solid transparent;
  border-right-width: 0;
  border-left-width: 20px;
}

.unique-1337-nav-tabs.unique-1337-wizard li:last-child {
  margin-right: 0;
}

.unique-1337-nav-tabs.unique-1337-wizard li > *::after {
  z-index: 2;
  border-left-color: inherit;
}

*,
::after,
::before {
  box-sizing: border-box;
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  4% {
    -webkit-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -webkit-transform: rotateZ(-3deg);
  }
  13% {
    -webkit-transform: rotateZ(2.5deg);
  }
  18% {
    -webkit-transform: rotateZ(-1.5deg);
  }
  24% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
  }
}

@-moz-keyframes wiggle {
  0% {
    -moz-transform: rotateZ(0deg);
  }
  4% {
    -moz-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -moz-transform: rotateZ(-3deg);
  }
  13% {
    -moz-transform: rotateZ(2.5deg);
  }
  18% {
    -moz-transform: rotateZ(-1.5deg);
  }
  24% {
    -moz-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -moz-transform: rotateZ(0deg);
  }
}

@-o-keyframes wiggle {
  0% {
    -o-transform: rotateZ(0deg);
  }
  4% {
    -o-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -o-transform: rotateZ(-3deg);
  }
  13% {
    -o-transform: rotateZ(2.5deg);
  }
  18% {
    -o-transform: rotateZ(-1.5deg);
  }
  24% {
    -o-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -o-transform: rotateZ(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotateZ(0deg);
  }
  4% {
    transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    transform: rotateZ(-3deg);
  }
  13% {
    transform: rotateZ(2.5deg);
  }
  18% {
    transform: rotateZ(-1.5deg);
  }
  24% {
    transform: rotateZ(0deg) scale(1);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.unique-1337-wobble {
  -webkit-animation: wiggle 3s ease infinite;
  -moz-animation: wiggle 3s ease infinite;
  -o-animation: wiggle 3s ease infinite;
  animation: wiggle 3s ease infinite;
}

.unique-1337-button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 24px;
  border: 1px solid #0000ff;
  border-radius: 8px;
  background: #00ff93;
  background: -webkit-gradient(linear, left top, left bottom, from(#00ff93), to(#00b35a));
  background: -moz-linear-gradient(top, #00ff93, #00b35a);
  background: linear-gradient(to bottom, #00ff93, #00b35a);
  text-shadow: #008040 1px 1px 1px;
  font: normal normal bold 20px arial;
  color: #ffffff;
  user-select: none;
  text-decoration: none;
}

.unique-1337-button:hover {
  background: #00ffb0;
  background: -webkit-gradient(linear, left top, left bottom, from(#00ffb0), to(#00d76c));
  background: -moz-linear-gradient(top, #00ffb0, #00d76c);
  background: linear-gradient(to bottom, #00ffb0, #00d76c);
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}

.modal-title {
  width: 100% !important;
}
// .unique-1337-button::before {
//     content: '\0000a0';
//     display: inline-block;
//     height: 24px;
//     width: 24px;
//     line-height: 24px;
//     margin: 0 4px -6px -4px;
//     position: relative;
//     top: 0px;
//     left: 0px;
//     background: url(http://cdn1.iconfinder.com/data/icons/CrystalClear/48x48/actions/agt_reload.png)
//         no-repeat left center transparent;
//     background-size: 100% 100%;
// }
