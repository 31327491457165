// For Collaspe And Accordion

// for vuexy collapse
.vx-collapse {
  .collapse-title {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .opening,
  .collapse-shown {
    .collapse-icon {
      transition: all 0.25s ease-in-out;
      transform: rotate(180deg);
    }
  }

  .collapse-icon {
    transition: all 0.25s ease-in-out;
    transform: rotate(0deg);
  }

  .card {
    cursor: pointer;
  }
}

// For collapse title
.collapse-title {
  color: inherit;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
}

// To add a border below collapse/accordion heading
.collapse-bordered {
  .card:first-child {
    border-top: 0;
  }

  .card:last-child {
    border-bottom: 0;
  }

  .card {
    margin-bottom: 0;
    border-bottom: 1px solid rgba($pure-black, 0.04);
    border-radius: 0;

    .card-header {
      padding: 1rem;
    }

    .card-body {
      padding: 1rem;
      line-height: 1.5;
    }
  }
}

.collapse-border-item {
  &.card {
    border: 1px solid rgba($pure-black, 0.1);
    margin-bottom: 0;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .card-header {
      padding: 1rem;
    }

    .card-body {
      padding: 1rem;
      line-height: 1.5;
    }
  }
}

// For Collapse with shadow
.accordion-shadow {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.15rem 0.75rem;
  border-radius: $border-radius;

  .card {
    &.open {
      border-radius: 0.571rem;
      margin: 10px 0;
      border: 0;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
    }
  }

  .card:first-child {
    border-top-left-radius: 0.571rem;
    border-top-right-radius: 0.571rem;
  }

  .card:last-child {
    border-bottom-left-radius: 0.571rem;
    border-bottom-right-radius: 0.571rem;
  }
}

// For Collapse with border
.collapse-border {
  border: 1px solid rgba($pure-black, 0.1);
  border-radius: 0.5rem;

  & + .collapse.show {
    border: 1px solid rgba($pure-black, 0.1);
  }
}

// For Collapse with margin
.collapse-margin {
  margin-top: 0.71rem;
  margin-bottom: 0.71rem !important;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  border-bottom: 0 solid transparent !important;
  border-radius: 5px !important;

  .card {
    margin-bottom: 0;
    border-radius: 5px !important;

    .card-header {
      padding: 1rem;
      border-radius: 5px;
    }

    .card-body {
      padding: 1rem;
      line-height: 1.5rem;
    }
  }
}
