.items-container {
  background-color: #f2f2f2 !important;
}
.collapse-item {
  border: solid 1px rgb(188, 188, 188);
  border-radius: 5px;
}
.collapse-btn-close {
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
}