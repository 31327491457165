.pulser {
  animation: pulser 2s ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes pulser {
  0% {
    transform: scale(0.9);
  }
  23% {
    transform: scale(1.1);
  }
  45% {
    transform: scale(0.9);
  }
  65% {
    transform: scale(1.015);
  }
  80% {
    transform: scale(0.987);
  }
  95% {
    transform: scale(1.005);
  }
  100% {
    transform: scale(0.9);
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: rotateZ(0deg);
  }
  4% {
    -webkit-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -webkit-transform: rotateZ(-3deg);
  }
  13% {
    -webkit-transform: rotateZ(2.5deg);
  }
  18% {
    -webkit-transform: rotateZ(-1.5deg);
  }
  24% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -webkit-transform: rotateZ(0deg);
  }
}

@-moz-keyframes wiggle {
  0% {
    -moz-transform: rotateZ(0deg);
  }
  4% {
    -moz-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -moz-transform: rotateZ(-3deg);
  }
  13% {
    -moz-transform: rotateZ(2.5deg);
  }
  18% {
    -moz-transform: rotateZ(-1.5deg);
  }
  24% {
    -moz-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -moz-transform: rotateZ(0deg);
  }
}

@-o-keyframes wiggle {
  0% {
    -o-transform: rotateZ(0deg);
  }
  4% {
    -o-transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    -o-transform: rotateZ(-3deg);
  }
  13% {
    -o-transform: rotateZ(2.5deg);
  }
  18% {
    -o-transform: rotateZ(-1.5deg);
  }
  24% {
    -o-transform: rotateZ(0deg) scale(1);
  }
  100% {
    -o-transform: rotateZ(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: rotateZ(0deg);
  }
  4% {
    transform: rotateZ(3.5deg) scale(1.2);
  }
  8% {
    transform: rotateZ(-3deg);
  }
  13% {
    transform: rotateZ(2.5deg);
  }
  18% {
    transform: rotateZ(-1.5deg);
  }
  24% {
    transform: rotateZ(0deg) scale(1);
  }
  100% {
    transform: rotateZ(0deg);
  }
}

.wobble {
  -webkit-animation: wiggle 3s ease infinite;
  -moz-animation: wiggle 3s ease infinite;
  -o-animation: wiggle 3s ease infinite;
  animation: wiggle 3s ease infinite;
}

.animated-dollars {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;

  & > span {
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 2s ease;
    transform: translateY(0);
    line-height: 1;

    span {
      flex: 0 0 100%;
      height: 100%;
    }
  }

  $d: 0;
  $inc: 0.375;
  @for $i from 1 through 20 {
    &:nth-child(#{$i}) > span {
      transition-delay: #{$d * 1s};
    }
    $d: $d + $inc;
  }
}

.fade-in {
  animation-delay: 1s;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
