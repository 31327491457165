// =========================================================================================
//   File Name: autocomplete.scss
//   Description: Custom vuexy autocomplete component.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: autocomplete
// ========================================================================

.vx-autocomplete-container {
  position: relative;

  .vx-autocomplete-search {
    height: 100%;
    width: 100%;
    background-color: $white;

    &:focus {
      box-shadow: none;
    }
  }

  .suggestions-list {
    z-index: 998;
    list-style: none;
    padding-left: 0;
    border-radius: 0.5rem;
    max-height: 450px;
    height: auto;
    width: 100%;
    position: absolute;
    margin-top: 0.5rem;
    background-color: $white;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

    .suggestion-item {
      padding: 0.85rem 1rem;
      color: $body-color;

      a {
        color: $body-color;
      }

      &.active,
      &:hover:not(.suggestion-title):not(.no-result) {
        background-color: $body-bg;
        color: $body-color;
      }

      &:hover:not(.suggestion-title):not(.no-result) {
        cursor: pointer;
      }
    }
  }
}
